<template>
    <div>
        <div class="content-header clearfix">
            <h1 class="float-left">
                {{ $t('pages.account.deposit') }}
                <small>
                    <slot name="back">
                        <CIcon name="cil-arrow-circle-left"></CIcon>
                        <a href="javascript:void(0)" @click="onCancel">{{ $t('common.back') }}</a>
                    </slot>
                </small>
            </h1>
        </div>
        <CRow>
            <CCol sm="12">
                <Accordion :headerTitle="$t('pages.deposit.recipientOfaccountInformation')" expand>
                    <template v-slot:body>
                        <div v-if="adminBankAccounts != null">
                            <CRow v-for="(item) in adminBankAccounts" v-bind:key="item.id">
                                <CCol sm="2">
                                    <b> {{$t('pages.bank.account')}}: {{ item.bankCode }}</b>
                                </CCol>
                                <CCol sm="6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label><b>{{ $t('pages.bank.bankName') }}</b></label>
                                        </div>
                                        <div class="col-md-6">
                                            <p><b>{{ item.bankName }}</b></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountName') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountName }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountNo') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountNo }}</p>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </template>
                </Accordion>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <Accordion :headerTitle="$t('pages.deposit.payerInformation')">
                    <template v-slot:body>
                        <div>
                            <CCard>
                                <CCardBody>
                                    <CForm>
                                        <CInput :placeholder="$t('pages.deposit.enterpayerName')" v-model="payerName" required horizontal :is-valid="validator">
                                            <template #label>
                                                <div class="col-sm-3 col-form-label">
                                                    {{ $t('pages.deposit.payerName') }}
                                                    <small class="requiredInput">(*)</small>
                                                </div>
                                            </template>
                                        </CInput>
                                        <CInput :placeholder="$t('pages.bank.accountNo')" v-model="accountNo" required horizontal :is-valid="validator">
                                            <template #label>
                                                <div class="col-sm-3 col-form-label">
                                                    {{ $t('pages.bank.accountNo') }}
                                                    <small class="requiredInput">(*)</small>
                                                </div>
                                            </template>
                                        </CInput>

                                        <CurrencyInput v-model="amount" :label="$t('common.amount')">
                                            <template v-slot:headerRight>
                                                <small class="requiredInput">(*)</small>
                                            </template>
                                        </CurrencyInput>

                                        <CTextarea :placeholder="$t('pages.deposit.contents')" v-model="contents" horizontal>
                                            <template #label>
                                                <div class="col-sm-3 col-form-label">
                                                    {{ $t('pages.deposit.contents') }}
                                                </div>
                                            </template>
                                        </CTextarea>

                                        <div role="group" class="form-group form-row">
                                            <label class="col-sm-3 col-form-label">
                                                {{ $t('pages.deposit.banktransactionproof') }}
                                                <small class="requiredInput">(*)</small>
                                            </label>
                                            <div class="col-sm-9 input-group">
                                                <UploadImages ref="uploadimages" :max="1" @change="onUploadImage" :uploadMsg="$t('common.uploadImageMessage')" Required :maxError="$t('common.maxfilesexceed')" />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <CButton type="button" color="primary" @click="onDeposit">{{ $t('pages.account.deposit') }}</CButton>
                                        </div>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </div>
                    </template>
                </Accordion>

                <Confirmation ref="confirmation"></Confirmation>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import Accordion from '@/components/Accordion.vue'
    import UploadImages from "vue-upload-drop-images"
    import CurrencyInput from '@/components/CurrencyInput.vue'
    
    export default {
        name: 'Deposit',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },
                warningModal: false,
                payerName: '',
                accountNo: '',
                selectedFile: null,
                amount: 0,
                contents: '',              
            };
		},
        components: {
            UploadImages,
            Confirmation,
            CurrencyInput,
            Accordion
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('deposit', ['hasError', 'errorMessage']),
            ...mapState('bank', ['adminBankAccounts'])
        },
        methods: {
            ...mapActions('bank', ['getBankAccountOfSystem']),

            validator(val) {
                return val ? val.length > 0 : false
            },
            onCancel() {
                this.$router.push('/users/profile');                 
            },
            async onDeposit() {
                if (this.payerName === '') {                   
                    this.$store.dispatch('alert/error', this.$t('pages.deposit.enterpayerName'), { root: true });
                    return;
                }
                if (this.accountNo === '') {                   
                    this.$store.dispatch('alert/error', this.$t('pages.bank.accountNo'), { root: true });
                    return;
                }

                if (isNaN(this.amount))
                    this.amount = 0;

                let _amount = parseFloat(this.amount);               
                if (_amount<= 0) {                   
                    this.$store.dispatch('alert/error', this.$t('pages.deposit.entertheamount'), { root: true });
                    return;
                }

                if (this.selectedFile === null) {                    
                    this.$store.dispatch('alert/error', this.$t('pages.deposit.uploadProofOfBankTransactions'), { root: true });
                    return;
                }
                
                // begin upload
                var retVal = await this.$store.dispatch("deposit/addDepositOrder", {
                    payerName: this.payerName,
                    accountNo: this.accountNo,
                    amount: _amount,
                    files: this.selectedFile,
                    contents: this.contents
                });
                
                if (retVal.success === true) {                   
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.deposit.noticesDeposit') });

                    this.$router.push('/history/deposithistory');
                }
                else {
                    let message = i18n.t('common.unsuccessfull');
                    if (retVal.errorMessage != '') {
                        switch (retVal.errorMessage) {
                            case 'Invalid AccountNo': message = i18n.t('pages.bank.accountNo'); break;
                            case 'Invalid PayerName': message = i18n.t('pages.deposit.enterpayerName'); break;
                            case 'Invalid File': message = i18n.t('pages.deposit.uploadProofOfBankTransactions'); break;
                            case 'Invalid Amount': message = i18n.t('pages.deposit.entertheamount'); break;
                            case 'not verified account': message = i18n.t('pages.account.noticeNoVerifyAccount'); break;
                        }
                    }
                    this.$store.dispatch('alert/error', message);
                }
            },
            onUploadImage(files) {
                this.selectedFile = files;               
            }
		},
        mounted() {
            this.getBankAccountOfSystem();           
        }
	}
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf;
        }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    a:hover {
        color: #0056b3;
        text-decoration: none;
    }

    a {
        color: #0056b3;
    }

    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    .content-header > h1 {
        margin-bottom: 10px;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

        .content-header > h1 > small i {
            font-size: .8em;
            padding-right: 2px;
        }

    header {
        background: #3c4b64
    }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .float-right button {
        margin-left: 0.2rem;
    }
</style>